<template>
    <Transition name="fade">
        <section class="misc-tooltip">
            <slot></slot>
            <svg class="misc-tooltip__svg" width="8" height="8">
                <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
            </svg>
        </section>
    </Transition>
</template>

<style lang="postcss" scoped>
.misc-tooltip {
    @apply absolute inset-x-0 -top-3 z-[1000] mx-auto flex w-fit items-center justify-center gap-2.5;
    @apply -translate-y-full transform rounded-xl border bg-white p-5 text-base leading-tight text-gray-900 shadow-2xl;
    &__svg {
        @apply absolute z-10 ml-4 h-6 w-6 -translate-x-1/2 translate-y-9 transform fill-current stroke-current text-white;
    }
}
</style>
