<template>
    <section class="share-block">
        <div class="content-head">
            <img :src="$assets.primary.share" alt="compartir" />
            <h3 class="content-head__heading">{{ $lang.components.sidebarShare.share }}</h3>
        </div>
        <div class="content-body">
            <button
                v-if="socials.twitter"
                type="button"
                class="content-body__twitter"
                @click="shareTwitter()"
            >
                <img :src="$assets.social.twitter" alt="twitter" /><span>Twitter</span>
            </button>
            <button
                v-if="socials.facebook"
                type="button"
                class="content-body__facebook"
                @click="shareFacebook()"
            >
                <img :src="$assets.social.facebook" alt="facebook" /><span>Facebook</span>
            </button>
            <button
                v-if="socials.whatsapp"
                type="button"
                class="content-body__whatsapp"
                @click="shareWhatsapp()"
            >
                <img :src="$assets.social.whatsapp" alt="whatsapp" /><span>WhatsApp</span>
            </button>
            <button
                v-if="socials.messenger"
                type="button"
                class="content-body__messenger"
                @click="shareMessenger()"
            >
                <img :src="$assets.social.messenger" alt="Messenger" /><span>Messenger</span>
            </button>
            <button
                v-if="socials.copylink"
                type="button"
                class="content-body__copylink"
                @click="copyToClipboard()"
            >
                <img :src="$assets.gray.link" :alt="$lang.components.sidebarShare.copy_link" /><span>{{
                    $lang.components.sidebarShare.copy_link
                }}</span>
                <MiscTooltip v-if="showCopyTooltip" :close="false">{{
                    $lang.components.sidebarShare.copied
                }}</MiscTooltip>
            </button>
            <button v-if="socials.email" type="button" class="content-body__email" @click="shareEmail()">
                <img :src="$assets.gray.messagesHeader" alt="Email" /><span>{{
                    $lang.components.sidebarShare.email
                }}</span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
export default defineComponent({
    name: 'SidebarShare',
    props: {
        socials: {
            type: Object,
            default() {
                return {
                    facebook: true,
                    messenger: true,
                    twitter: true,
                    whatsapp: true,
                    copylink: false,
                    email: false,
                }
            },
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        const copyText = useClipboard()
        return {
            copyText,
            showCopyTooltip: false,
        }
    },
    computed: {
        fullpage(): string {
            return window.location.host + this.$route.fullPath
        },
    },
    methods: {
        shareTwitter() {
            window.open(`https://twitter.com/intent/tweet?text=${this.title}&url=${this.fullpage}`, '_blank')
        },
        shareFacebook() {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.fullpage}`, '_blank')
        },
        shareWhatsapp() {
            window.open(`https://api.whatsapp.com/send?text=${this.title + ' ' + this.fullpage}`, '_blank')
        },
        shareMessenger() {
            window.open(`https://www.messenger.com/t/${this.title + ' ' + this.fullpage}`, '_blank')
        },
        copyToClipboard() {
            this.copyText.copy(this.fullpage)
            this.showCopyTooltip = true
            setTimeout(() => {
                this.showCopyTooltip = false
            }, 2000)
        },
        shareEmail() {
            window.open(`mailto:?body=${this.title + ' ' + this.fullpage}`, '_blank')
        },
    },
})
</script>

<style lang="postcss" scoped>
.share-block {
    @apply rounded-2xl border border-opacity-5 bg-white p-3;
    .content-head {
        @apply mb-2 flex gap-2;
        &__heading {
            @apply text-lg font-thin;
        }
    }
    .content-body {
        @apply flex flex-col gap-y-2 text-white;

        &__twitter {
            @apply hidden justify-center gap-2 rounded-xl bg-gradient-to-r from-[#3384FF] to-[#33C8FF] p-2 sm:flex;
        }
        &__facebook {
            @apply hidden justify-center gap-2 rounded-xl bg-gradient-to-r from-[#3A51CA] to-[#3031AB] p-2 sm:flex;
        }
        &__whatsapp {
            @apply flex justify-center gap-2 rounded-xl bg-gradient-to-r from-[#5FF87B] to-[#4BB663] p-2 md:hidden;
        }
        &__messenger {
            @apply flex justify-center gap-2 rounded-xl bg-gradient-to-r from-[#3378FF] via-[#AF00CB] to-[#FF6968] p-2 md:hidden;
        }
        &__copylink {
            @apply relative flex justify-center gap-2 rounded-xl bg-gray-200 p-2 text-gray-800;
            span {
                @apply text-gray-800;
            }
        }
        &__email {
            @apply flex justify-center gap-2 rounded-xl bg-gray-200 p-2 text-gray-800;
            span {
                @apply text-gray-800;
            }
        }
        img {
            @apply h-6 w-6;
        }
        span {
            @apply self-center;
        }
    }
}
</style>
