<template>
    <section class="post-data">
        <NuxtLink no-prefetch v-if="post.user" :to="'/dashboard/' + post.user.id" rel="follow" class="author">
            <picture class="image">
                <MiscUserPic cass="h-10 w-10" :user="post.user" />
            </picture>
            <p class="name">{{ post.user.username }}</p>
            <MiscBadge :text="post.user.role.name" />
        </NuxtLink>
        <div class="dates">
            <div class="top">
                <p v-if="post.created_at" class="created">
                    <img :src="$assets.gray.createdAt" alt="icono" />
                    <span class="wraptext">{{ $lang.components.cardPostUserInfo.created_at }}</span>
                    <span class="textbold">{{ formatDate(post.created_at) }}</span>
                </p>
                <p v-if="post.reading_time" class="readtime">
                    <img :src="$assets.gray.readtime" alt="icono" />
                    <span class="wraptext">{{ $lang.components.cardPostUserInfo.reading_time }}</span>
                    <span class="textbold"
                        >{{ post.reading_time }} {{ $lang.components.cardPostUserInfo.minutes }}</span
                    >
                </p>
            </div>
            <div class="bottom">
                <p v-if="post.created_at !== post.updated_at" class="refreshed">
                    <img :src="$assets.gray.lastRefresh" alt="icono" />
                    <span class="wraptext">{{ $lang.components.cardPostUserInfo.last_update }}</span>
                    <span v-if="post.updated_at" class="textbold">{{ formatDate(post.updated_at) }}</span>
                </p>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { format } from 'date-fns'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardPostUserInfo',
    props: {
        post: {
            type: Object as PropType<Models.Article | Models.Novelty>,
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {
        formatDate(date: string): string {
            const newDate = new Date(date)
            return (
                format(newDate, 'dd/MM/yyyy') +
                ` ${this.$lang.components.cardPostUserInfo.at} ` +
                format(newDate, 'hh:ss') +
                'h'
            )
        },
    },
})
</script>

<style lang="postcss" scoped>
.post-data {
    @apply my-4 items-center justify-between space-y-2 text-sm md:text-base lg:my-0;
    .author {
        @apply flex items-center justify-center gap-2;
        .image {
            @apply h-10 w-10 self-center rounded-full object-cover md:h-12 md:w-12;
            img {
                @apply h-full w-full rounded-full;
            }
        }
        .name {
            @apply whitespace-nowrap font-semibold text-gray-800 hover:underline;
        }
    }
    .dates {
        @apply block space-y-2 text-gray-800;
        .top {
            @apply flex flex-wrap items-center justify-center gap-x-5 gap-y-2 md:justify-end;
            .created {
                @apply flex items-center gap-1;
            }
            .readtime {
                @apply flex items-center gap-1;
            }
        }
        .bottom {
            .refreshed {
                @apply flex items-center justify-center gap-1 md:justify-end;
            }
        }
        .wraptext {
            @apply overflow-hidden text-ellipsis whitespace-nowrap;
        }
        .textbold {
            @apply whitespace-nowrap font-semibold text-gray-800;
        }
    }
}
</style>
