<template>
    <div class="blog-post-index-page">
        <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" styling="flex lg:hidden" />
        <aside class="blog-post-index-page__sidebar">
            <div class="sidebar-wrapper">
                <div v-if="isIndexAvailable" class="indice hidden lg:block">
                    <h2 class="title">{{ $lang.pages.blogArticle.index }}</h2>
                    <ul>
                        <li v-for="(item, key) in postIndex" :key="key" @click="gotoPostId(`#${item.id}`)">
                            {{ item.text }}
                        </li>
                    </ul>
                </div>
                <button class="valorate hidden lg:flex" @click="makeVote">
                    <img
                        :src="valorated ? $assets.primary.valorateFilled : $assets.primary.valorate"
                        :alt="$lang.pages.blogArticle.valorate"
                    />
                    {{ $lang.pages.blogArticle.valorate }}
                </button>
                <BWidgetShare
                    class="hidden lg:block"
                    :socials="{
                        facebook: true,
                        twitter: true,
                        messenger: true,
                        whatsapp: true,
                        copylink: true,
                        email: true,
                    }"
                    :title="storedArticle.data.article.title"
                />
            </div>
        </aside>

        <main class="main-post">
            <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" styling="hidden lg:flex" />
            <h1 v-if="storedArticle.data.article.title" class="main-post__title">
                {{ storedArticle.data.article.title }}
            </h1>
            <div v-if="storedArticle.data.article.image" class="main-post__image">
                <img
                    :src="storedArticle.data.article.image.url"
                    :alt="storedArticle.data.article.title"
                    :title="storedArticle.data.article.title"
                />
            </div>

            <div v-if="isIndexAvailable" class="indice lg:hidden">
                <h2 class="title">{{ $lang.pages.blogArticle.index }}</h2>
                <ul>
                    <li v-for="(item, key) in postIndex" :key="key" @click="gotoPostId(`#${item.id}`)">
                        {{ item.text }}
                    </li>
                </ul>
            </div>

            <div
                id="post"
                ref="post"
                class="main-post__content"
                v-html="removeXSS(storedArticle.data.article.content)"
            ></div>

            <BWidgetUser :post="storedArticle.data.article" class="block md:flex" />

            <button class="valorate flex lg:hidden" @click="makeVote">
                <img
                    :src="valorated ? $assets.primary.valorateFilled : $assets.primary.valorate"
                    :alt="$lang.pages.blogArticle.valorate"
                />
                {{ $lang.pages.blogArticle.valorate }}
            </button>
            <BWidgetShare
                class="block lg:hidden"
                :socials="{
                    facebook: true,
                    twitter: true,
                    messenger: false,
                    whatsapp: true,
                    copylink: true,
                    email: true,
                }"
                :title="storedArticle.data.article.title"
            />
            <div class="blog-posts-3">
                <div class="blog-posts-3__head">
                    <h2 class="blog-posts-3__head__title">
                        {{ $lang.pages.blogArticle.similar_articles }}
                    </h2>
                </div>
                <ul class="blog-posts-3__wrapper">
                    <li v-for="(item, key) in storedArticle.data.similar.slice(0, 3)" :key="key" class="post">
                        <BCardBasic
                            :post="item"
                            :category="item.category ? item.category.slug : 'undefined'"
                        />
                    </li>
                </ul>
            </div>
        </main>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import { removeXSS, replacePlaceholders, textShortener } from '~/util/textFunction'
import type { Api } from '~~/global'

type postIndex = {
    id: string
    text: string
}

const { $lang } = useNuxtApp()

const Route = useRoute()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('blog-article-data', () => {
    return $fetch<Api.Responses.Pages.Articles | Api.Responses.ResourceNotFound>(
        (endpoints.pages.articles.path + '/' + Route.params.article) as string,
        {
            headers: buildHeaders(),
            method: 'GET',
            baseURL,
        },
    ).catch((e) => e.data)
})
if (
    !responseData.value ||
    !responseData.value ||
    error.value ||
    responseData.value.error ||
    responseData.value.feedback !== 'data_success'
) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}
const storedArticle = ref(responseData.value)

const isIndexAvailable = ref(false)

const postIndex = ref([]) as Ref<postIndex[]>

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.blogArticle.home,
        },
        {
            path: `/${$lang.routes.blog}`,
            title: $lang.pages.blogArticle.blog,
        },
        {
            path: `/${$lang.routes.blog}/` + storedArticle.value.data.article.category?.slug,
            title: storedArticle.value.data.article.category?.name || '',
        },
        {
            title: storedArticle.value.data.article.title || '',
        },
    ]
})

const gotoPostId = useScrollToComponent()

const $post = ref() as Ref<HTMLElement>

const getTextIndex = () => {
    if ($post.value) {
        const h2 = $post.value.querySelectorAll('h2')
        // check if h2 element has text, then get innerText and id and creates a object with it, and sets an id to each h2
        if (h2.length > 0) {
            h2.forEach((el: HTMLElement, key: number) => {
                if (el.innerText.length > 0) {
                    const id = 'post-' + key
                    el.id = id
                    postIndex.value.push({
                        id,
                        text: el.innerText,
                    })
                }
            })
        }
        if (postIndex.value.length > 0) isIndexAvailable.value = true
    }
}

onMounted(() => getTextIndex())

const valorated = ref(false)

const likes = ref([]) as Ref<Array<number | string>>

const { setSiteNotification } = useRootStore()

let makeVote = (() => null) as () => any

onMounted(() => {
    makeVote = async () => {
        if (!valorated.value) {
            const body = {
                likeable_id: storedArticle.value.data.article.id,
                type: valorated ? 0 : 1,
                likeable_type: 'article',
            }
            const res = await $fetch<Api.Responses.Actions.SuccessLike>(endpoints.create.likes.path, {
                baseURL,
                method: 'POST',
                headers: buildHeaders(),
                body,
            })

            if (res.feedback === 'data_success') {
                valorated.value = !valorated.value
                if (localStorage) {
                    likes.value = [...likes.value, responseData.value.data.article.id]
                    localStorage.setItem('blog-likes-ids', JSON.stringify(likes.value))
                }
            } else {
                setSiteNotification({
                    duration: 3000,
                    dismissButtonText: $lang.pages.blogArticle.hide,
                    text: $lang.pages.blogArticle.error_on_valorate,
                    type: 'error',
                })
            }
        }
    }
    likes.value = JSON.parse((localStorage.getItem('blog-likes-ids') as string) || '[]')

    valorated.value = !!likes.value.find((id) => id === responseData.value.data.article.id)
})
const { currentDate } = useDateFunctions()

const currentDates = currentDate()

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':title', storedArticle.value.data.article.title || ''],
        [
            ':description',
            storedArticle.value.data.article.content?.replace(
                /<\/?[a-z][a-z0-9]*[^<>]*>|<!--.*?-->/gim,
                '',
            ) || '',
        ],
        [':site', siteName || ''],
    ],
    [
        storedArticle.value.data.article.seo?.title || '',
        storedArticle.value.data.article.seo?.description || '',
    ],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.blog-post-index-page {
    @apply container mb-20 gap-x-6 lg:flex;
    &__sidebar {
        @apply top-20 mt-2 block h-full lg:sticky lg:mt-14 lg:w-3/12 lg:space-y-4;
        .sidebar-wrapper {
            @apply space-y-2;
        }
    }
    .indice {
        .title {
            @apply text-base font-semibold text-gray-800 sm:text-lg;
        }
        ul {
            @apply my-5 list-inside list-decimal space-y-3;
            li {
                @apply cursor-pointer gap-y-2 text-gray-800 hover:underline;
            }
        }
    }

    .main-post {
        @apply flex w-full flex-col gap-y-5 lg:w-9/12;
        &__title {
            @apply text-2xl font-semibold text-gray-800;
        }
        &__image {
            @apply h-48 w-full sm:h-60 md:h-80 lg:h-96;
            img {
                @apply h-full w-full object-cover;
            }
        }
        &__content {
            @apply prose prose-gray max-w-none px-1 text-base lg:py-2;
        }

        .blog-posts-3 {
            @apply space-y-5;
            &__head {
                @apply flex justify-between;
                &__title {
                    @apply text-xl font-semibold text-gray-800;
                }
            }
            &__wrapper {
                @apply grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3;
            }
        }
    }
}
.valorate {
    @apply w-full items-center justify-center gap-1 rounded-xl border bg-white p-3 text-gray-800 hover:border-gray-300;
}
</style>
